<template>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="history">
            <van-sticky :offset-top="0.8" z-index="999">
                <!--<div class="history-header">
                            <van-row type="flex" justify="center" style="background: #13b5b1">
                                <van-col span="8" style="text-align: left;padding: 15px;font-size: 16px;font-weight: 500;color: #fff;">交易历史</van-col>
                                <van-col span="8" style="text-align: center;padding: 15px;font-size: 16px;font-weight: 500;color: #fff;">交易历史</van-col>
                            </van-row>
                        </div>-->
                <van-nav-bar class="historyTitle"
                             :title="$t('Mt4ApiMenu.history')"
                             :left-text="formatPrice(totalProfit, 2) + ' ' + currency" />
                <div class="history-date">
                    <NavBar>
                        <div class="slot-left" slot="left">
                            <van-image width="25"
                                       height="25"
                                       :src="calendar" @click="calendarShow" />
                            <van-calendar v-model="show"
                                          type="range"
                                          allow-same-day
                                          color="#13b5b1"
                                          :min-date="minDate"
                                          :max-date="maxDate"
                                          :round="false"
                                          position="right"
                                          @confirm="onConfirm" />
                        </div>
                        <div class="history-time"
                             slot="title"
                             style="color: #13b5b1; font-size: 12px">
                            {{ searchDate }}
                        </div>
                        <span class="history-time"
                              slot="right"
                              style="color: #13b5b1; font-size: 12px"
                              @click="all">{{ $t("Mt4ApiTrade.showAll") }}</span>
                    </NavBar>

                    <van-search v-model="searchText"
                                input-align="left"
                                :placeholder="$t('Mt4ApiTrade.searchSymbol')"
                                @search="onSearch"
                                shape="round" />
                </div>
            </van-sticky>
            <div class="history-content">
                <div class="content-show">
                    <div class="h-radius">
                        <van-collapse v-model="activeNames">
                            <van-collapse-item v-for="(item, index) in records" :key="index">
                                <template #title>
                                    <div v-if="item.type <= 5">
                                        <strong style="font-weight: 700">{{ item.symbol }},</strong>&nbsp;<span style="font-weight: 700"
                                                                                                                :style="{ color: formatColor(item.type) }">
                                            {{ formattype(item.type) }}&nbsp;&nbsp;&nbsp;{{
                        formatVolume(item.volume)
                                            }}
                                        </span>
                                        <div>
                                            {{ formatPrice(item.open_price, item.digits) }} &nbsp;<i class="fa fa-long-arrow-right"
                                                                                                     aria-hidden="true"></i>
                                            &nbsp;{{ formatPrice(item.close_price, item.digits) }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <span style="font-weight: 700"
                                              :style="{ color: formatColor(item.type) }">
                                            {{ formattype(item.type) }}&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </div>
                                </template>
                                <template #right-icon>
                                    <div>
                                        <div style="color: darkgray">
                                            {{
                        item.close_time
                          .replace(/T/g, " ")
                          .replace(/\.[\d]{3}Z/, "")
                                            }}
                                        </div>
                                        <div style="float: right">
                                            <span style="font-weight: 700"
                                                  :style="{ color: formatColor(item.profit) }">{{ formatPrice(item.profit, 2) }}</span>
                                        </div>
                                    </div>
                                </template>
                                <van-row>
                                    <van-col span="12">
                                        S/L:<span class="itemright">{{ formatSlTp(item.sl,item.digits) }}</span>
                                    </van-col>
                                    <van-col span="12">
                                        {{ $t("Report.swaps") }}:<span class="itemright">
                                            {{ formatPrice(item.storage, 2) }}
                                        </span>
                                    </van-col>
                                    <van-col span="12">
                                        T/P:<span class="itemright">{{ formatSlTp(item.tp, item.digits) }}</span>
                                    </van-col>
                                    <van-col span="12">
                                        {{ $t("Report.taxes") }}:<span class="itemright">
                                            {{ formatPrice(item.taxes, 2) }}
                                        </span>
                                    </van-col>
                                    <van-col span="12">
                                        ID:<span class="itemright">
                                            {{ item.ticket }}
                                        </span>
                                    </van-col>
                                    <van-col span="12">
                                        {{ $t("Report.charges") }}:<span class="itemright">
                                            {{ formatPrice(item.commission, 2) }}
                                        </span>
                                    </van-col>
                                </van-row>
                            </van-collapse-item>
                        </van-collapse>
                    </div>
                </div>
            </div>
        </div>
    </van-pull-refresh>
</template>
<script>
    import NavBar from "@/components/mt4/NavBar";
    import { Toast, Notify, Dialog } from "vant";
    import { GetHistory } from "@/api/mt4Api";
    import { formatType, forPrice } from "@/utils/commons";
    export default {
        components: { NavBar },
        data() {
            return {
                searchDate: "",
                show: false,
                minDate: new Date(2018, 0, 1),
                maxDate: new Date(),
                searchText: "",
                isLoading: false,
                activeNames: [],
                pageIndex: 1,
                pageSize: 20,
                records: [],
                allRecords: [],
                total: 0,
                totalProfit: 0,
                currency: "",
                calendar: require("../../assets/image/mt4tabbar/calendar.svg"),
            };
        },
        computed: {
            currentTime() {
                let dateTime;
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth() + 1;
                let dd = new Date().getDate();
                let hh = new Date().getHours();
                let mf =
                    new Date().getMinutes() < 10
                        ? "0" + new Date().getMinutes()
                        : new Date().getMinutes();
                let ss =
                    new Date().getSeconds() < 10
                        ? "0" + new Date().getSeconds()
                        : new Date().getSeconds();
                dateTime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
                console.log(dateTime);
                return dateTime;
            },
        },
        mounted() {
            this.$store.commit("toggleTabbarShow", true);
            this.$store.commit("toggleTabbarCurrentShow", "Mt4");
        },
        created() {
            this.loadData();

            let info = localStorage.getItem("accountinfo");
            let accountinfo = JSON.parse(info);
            this.currency = accountinfo.currency;
        },
        beforeDestroy() { },
        methods: {
            all() {
                this.searchDate = "";
                this.loadData();
            },
            calendarShow() {
                this.show = !this.show;
                // console.log(this.show);
            },
            formatDate(date) {
                return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
            },
            onConfirm(date) {
                const [start, end] = date;
                this.show = false;
                this.searchDate = `${this.formatDate(start)}-${this.formatDate(end)}`;
                //console.log(this.searchDate);
                this.loadData();
            },
            onSearch(val) {
                this.totalProfit = 0;
                this.searchText = val;
                //Toast(this.searchText);
                this.records = [];
                this.allRecords.forEach((element) => {
                    if (element.symbol.indexOf(val.toUpperCase()) != -1) {
                        //console.log(element.ticket);
                        this.records.push(element);
                        this.totalProfit += element.profit;
                    }
                });
            },
            onCancel() {
                Toast("ȡ��");
            },
            loadData() {
                let param = {
                    //offset: this.pageIndex,
                    //pageSize: 12,
                    dateTime: this.searchDate,
                    /* search: this.searchText*/
                };
                GetHistory(param).then((res) => {
                    this.totalProfit = 0;
                    this.isLoading = false;
                    if ((res.code = 200)) {
                        //console.log("rows"+res.msg.rows);
                        if (this.pageIndex == 1) {
                            this.records = res.msg.rows;
                            this.allRecords = res.msg.rows;
                            res.msg.rows.forEach((x) => {
                                this.totalProfit += x.profit;
                            });
                        } else {
                            res.msg.rows.forEach((element) => {
                                this.records.push(element);
                                this.allRecords.push(element);
                                this.totalProfit += x.profit;
                            });
                        }
                        this.total = res.msg.total;
                    } else {
                        Notify({ type: 'danger', message: this.$t(res.msg) });
                    }
                });
            },
            formattype(value) {
                return formatType(value);
            },
            formatVolume(value) {
                return (value * 0.01).toFixed(2);
            },
            formatColor(value) {
                if (value > 0) {
                    return "#13b5b1";
                }
                return "#ED7F35";
            },
            formatPrice(value, len) {
                return forPrice(value, len);
            },
            formatSlTp(value,digits) {
                if (value <= 0) {
                    return "-";
                }
                return this.formatPrice(value, digits);
            },
            onRefresh() {
                this.searchText = "";
                this.loadData();
            },
        },
    };
</script>

<style scoped>
    .history {
        overflow-y: auto;
        background-color: #ececec;
        height: 100vh;
        padding-bottom: 50px;
    }

    .itemright {
        float: right;
        padding-right: 10px;
    }

    .history-date {
        margin: 3px 0 3px 0;
        padding: 0 8px;
        background-color: #fff;
    }

    .history-content {
        margin: 0px 0 0 0;
    }

    .content-show {
        padding: 8px;
        background-color: #ececec;
    }

    .h-radius .van-collapse-item {
        margin-bottom: 5px;
        border-radius: 5px;
        overflow: hidden;
    }
</style>

<style>
    .historyTitle.van-nav-bar {
        background-color: #13b5b1;
        color: #fff !important;
    }

    .historyTitle .van-nav-bar__title {
        color: #fff !important;
    }

    .historyTitle .van-nav-bar__text {
        color: #fff !important;
    }
    /*.history-date .van-nav-bar {line-height:15px;
    }*/
    .history-date .van-nav-bar__content {
        height: 35px;
    }

    .history-date .van-search {
        padding-top: 5px;
    }
</style>